import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {KeyIdentifierModel} from '@creditsnap/data-models';
import {SharedKeyDataService} from './sharedkey-data.service';
import {APP_ENV_CONFIG} from '../index';


@Injectable({
	providedIn: 'root'
})
export class UnderwritingService {
	keyIdentifier: KeyIdentifierModel;
	currentFeatureData: Subject<any> = new Subject<any>();

	constructor(
		private http: HttpClient,
		private sharedKeyDataService: SharedKeyDataService,
		@Inject(APP_ENV_CONFIG) private env: any
	) {
		sharedKeyDataService.keyIdentifier$.subscribe((data) => {
			this.keyIdentifier = data;
		});
	}

	getDocusignUrl(applicationId: any) {
		const url = `${this.env.apiUrl}/docusign/embeddedurl`;
		const body = {
			returnUrl: `${window.location.protocol}//${window.location.host}/redirect?appId=${applicationId}&feature=esign`,
			applicationId,
			institutionCode: this.env.institutionId
		};
		return this.http.post<any>(url, body);
	}

	sendIdPalLink(applicationId: any, appendHeader = false) {
		const url = `${this.env.apiUrl}/idpal/app-link/send/${applicationId}`;
		return this.http.post<any>(url, {}, {
			...(appendHeader ? {
				headers: {'Communication-Type': 'TEXT'}
			} : {})
		});
	}

	checkIdPalStatus(applicationId: any) {
		const url = `${this.env.apiUrl}/idpal/app-link/status/${applicationId}`;
		return this.http.get<any>(url);
	}

	scheduleAppointment(appId: any) {
		const url = `${this.env.apiUrl}/appointment/${appId}`;
		return this.http.post<any>(url, {});
	}

	incomeVerification(appId) {
		const url = `${this.env.apiUrl}/fraud/incomeverification/${appId}`;
		return this.http.post<any>(url, {});
	}

	getIncomeVerificationStatus(appId: any) {
		const url = `${this.env.apiUrl}/fraud/incomeverification/status/${appId}`;
		return this.http.get<any>(url, {});
	}
}
