import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApplicationStatusEnum, KeyIdentifierModel} from '@creditsnap/data-models';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import { ApplicantModel, ApplicantType, ApplicationModel } from '../../data-models/src';
import { Inject } from "@angular/core";
import {APP_ENV_CONFIG} from "@creditsnap/app-config";
import { ApplicationService } from './application.service';
import { APP_CONFIG } from '../index';

@Injectable({
	providedIn: 'root'
})
export class SharedKeyDataService {
	keyIdentifier = new BehaviorSubject<KeyIdentifierModel>(new KeyIdentifierModel(true));
	keyIdentifier$ = this.keyIdentifier.asObservable();
	keyIdentifierModel = new KeyIdentifierModel(false);

	stepperStep: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(
		private applicationService: ApplicationService,
		private router: Router,
		@Inject(APP_ENV_CONFIG) private env: any,
		@Inject(APP_CONFIG) private appConfig: any,
		) {
		this.announceIdentifierChanges(new KeyIdentifierModel(true));
	}

	announceIdentifierChanges(identifier: KeyIdentifierModel) {
		this.keyIdentifier.next(identifier);
	}

	initializeIdentifier(identifier: KeyIdentifierModel): KeyIdentifierModel {
		if (identifier === undefined || identifier === null) {
			identifier = new KeyIdentifierModel(true);
		} else {
			identifier.onPageLoad = false;
		}
		return identifier;
	}

	isIdentifierAnnounced(identifier: KeyIdentifierModel): boolean {
		return !(identifier === undefined || identifier === null);
	}

	performApplicationNextAction(identifier: KeyIdentifierModel, isReentry = false): any {
		let navigationAction: string;
		let screenAction: string;
		let errorCode: string = '';
		console.log('performApplicationNextAction key identifier =>', identifier,identifier.applicationStatus);
		switch (identifier.applicationStatus) {
			case ApplicationStatusEnum.OTP_VERIFICATION_PENDING:
			case ApplicationStatusEnum.PROSPECT_APPLICATION:
				navigationAction = '/';
				screenAction = '';
				break;
			case ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED:
				navigationAction = '/';
				screenAction = 'co-borrower';
				break;
			case ApplicationStatusEnum.APPLICATION_SUBMITTED:
				if (identifier.isCashApplication) {
					navigationAction = '/thankyou';
					screenAction = 'thankyou';
					errorCode = 'CASH_APPLICATION';
				} else if (this.appConfig && this.appConfig.applicationFlow === 2 && isReentry) {
					navigationAction = '/';
					screenAction = '';
				} else if (identifier.loanPurposeConfig && identifier.loanPurposeConfig.skipOfferScreen) {
					if(identifier.loanPurposeConfig.collateral.required){
						screenAction = 'trade';
						navigationAction = '/trade';
					} else {
						navigationAction = '/review';
						screenAction = 'review';
					}
				} else if (identifier.loanPurposeConfig && identifier.loanPurposeConfig.id === 'CLI' && identifier.noTradelineAvailable) {
					navigationAction = '/review';
					screenAction = 'review';
				} else {
					navigationAction = '/offer';
					screenAction = 'offer';
				}
				break;
			case ApplicationStatusEnum.NO_OFFER_PEND:
			case ApplicationStatusEnum.NO_OFFER_FOUND:
			case ApplicationStatusEnum.NO_OFFER_HARD_DECLINE:
			case ApplicationStatusEnum.NO_OFFER_FRAUD_DECLINE:
				if (identifier.offerStatus === ApplicationStatusEnum.NO_OFFER_PEND) {
					navigationAction = '/review';
					screenAction = 'review';
				} else {
					navigationAction = '/thankyou';
					screenAction = 'thankyou';
					errorCode = identifier.offerStatus || 'NO_OFFER';
				}
				break;
			case ApplicationStatusEnum.NO_OFFER_PRE_FAIL:
				navigationAction = '/thankyou';
				screenAction = 'thankyou';
				errorCode = identifier.applicationStatus;
				break;
			case ApplicationStatusEnum.TRADELINE_SELECTED:
				if(!identifier.loanPurposeConfig.collateral.required) {
					if (identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_REPORT || identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_HIT_COBORROWER
							|| identifier.offerStatus === ApplicationStatusEnum.NO_OFFER_NO_HIT || identifier.offerStatus === ApplicationStatusEnum.TU_BUREAU_REPORT_LOCKED || identifier.offerStatus === ApplicationStatusEnum.XPN_BUREAU_REPORT_LOCKED) {
								if (identifier.loanPurposeConfig.finalSubmitNoHit) {
									navigationAction = '/review';
									screenAction = 'review';
								} else {
									navigationAction = '/thankyou';
									screenAction = 'thankyou';
									errorCode = 'NO_OFFER_NO_HIT';
								}
					} else if (identifier.loanPurposeConfig.skipOfferScreen) {
						screenAction = 'review';
						navigationAction = '/review';
					} else {
						screenAction = 'offer';
						navigationAction = '/offer';
					}
					
				}
				else {
					screenAction = 'trade'; //includes collateral page
					navigationAction = '/trade';
				}
				break;
			case ApplicationStatusEnum.NO_BUREAU_REPORT:
			case ApplicationStatusEnum.NO_BUREAU_HIT_COBORROWER:
				if (identifier.loanPurposeConfig.tradeLine.required || identifier.loanPurposeConfig.collateral.required) {
					if (identifier.applicationType === 'J' &&
						identifier.applicantsStatus === ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED) {
						navigationAction = '/';
						screenAction = 'co-borrower';
					} else {
						navigationAction = '/trade';
						screenAction = 'trade';
					}
				} else {
					if (identifier.applicationType === 'J' &&
						identifier.applicantsStatus === ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED) {
						navigationAction = '/';
						screenAction = 'co-borrower';
					} else if (identifier.loanPurposeConfig.finalSubmitNoHit) {
						navigationAction = '/review';
						screenAction = 'review';
					} else {
						navigationAction = '/thankyou';
						screenAction = 'thankyou';
						errorCode = 'NO_OFFER_NO_HIT';
					}
				}
				break;
			case ApplicationStatusEnum.BUREAU_REPORT_RECEIVED:
				if (identifier.loanPurposeConfig.tradeLine.required || identifier.loanPurposeConfig.collateral.required) {
					if (identifier.applicationType === 'J' &&
						identifier.applicantsStatus === ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED) {
						navigationAction = '/';
						screenAction = 'co-borrower';
					} else {
						navigationAction = '/trade';
						screenAction = 'trade';
					}
				} else {
					if (identifier.applicationType === 'J' &&
						identifier.applicantsStatus === ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED) {
						navigationAction = '/';
						screenAction = 'co-borrower';
					} else {
						screenAction = 'offer';
						navigationAction = '/offer';
					}
				}
				break;
			case ApplicationStatusEnum.COLLATERAL_ADDED:
				if (identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_REPORT || identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_HIT_COBORROWER
					|| identifier.offerStatus === ApplicationStatusEnum.NO_OFFER_NO_HIT || identifier.offerStatus === ApplicationStatusEnum.TU_BUREAU_REPORT_LOCKED || identifier.offerStatus === ApplicationStatusEnum.XPN_BUREAU_REPORT_LOCKED) {
						if (identifier.loanPurposeConfig.finalSubmitNoHit) {
							navigationAction = '/review';
							screenAction = 'review';
						} else {
							navigationAction = '/thankyou';
							screenAction = 'thankyou';
							errorCode = 'NO_OFFER_NO_HIT';
						}
				} else if(identifier.loanPurposeConfig.skipOfferScreen)
				{
					navigationAction = '/review';
					screenAction = 'review';
				} else if (identifier.loanPurposeConfig.productName === 'AUTO_SPECIALTY')
				{
						navigationAction = '/offer';
						screenAction = 'offer';
				} else {
					navigationAction = '/trade';
					screenAction = 'trade';
				}
				// Initiate the collateral valuation
				break;
			case ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED:
				if (identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_REPORT || identifier.offerStatus === ApplicationStatusEnum.NO_BUREAU_HIT_COBORROWER
					|| identifier.offerStatus === ApplicationStatusEnum.NO_OFFER_NO_HIT || identifier.offerStatus === ApplicationStatusEnum.TU_BUREAU_REPORT_LOCKED || identifier.offerStatus === ApplicationStatusEnum.XPN_BUREAU_REPORT_LOCKED) {
						if (identifier.loanPurposeConfig.finalSubmitNoHit) {
							navigationAction = '/review';
							screenAction = 'review';
						} else {
							navigationAction = '/thankyou';
							screenAction = 'thankyou';
							errorCode = 'NO_OFFER_NO_HIT';
						}
				} else if (identifier.loanPurposeConfig.skipOfferScreen) {
					screenAction = 'review';
					navigationAction = '/review';
				} else if (identifier.isOfferGenerated) {
					navigationAction = '/manage-offer';
					screenAction = 'manage-offer';
				} else {
					navigationAction = '/offer';
					screenAction = 'offer';
				}
				break;
			case ApplicationStatusEnum.OFFER_GENERATED:
			case ApplicationStatusEnum.OFFER_GENERATED_WITH_LTV:
				screenAction = 'manage-offer';
				navigationAction = '/manage-offer';
				break;
			case ApplicationStatusEnum.OFFER_SELECTED:
				if(this.env.institutionId === 'ORL'){
					navigationAction = '/thankyou';
					screenAction = 'thankyou';
				} else {
					navigationAction = '/review';
					screenAction = 'review';
				}
				break;
			case ApplicationStatusEnum.OFFER_GENERATION_INITIATED:
				navigationAction = '/offer';
				screenAction = 'offer';
				break;
			case ApplicationStatusEnum.PRE_FINANCIAL_SUBMITTED:
			case ApplicationStatusEnum.FINANCIAL_SUBMITTED_FAILED:
			case ApplicationStatusEnum.FINANCIAL_SUBMITTED:
			case ApplicationStatusEnum.FINANCIAL_SUBMITTED_FRAUD:
			case ApplicationStatusEnum.FINANCIAL_SUBMITTED_FRAUD_REVIEW:
			case ApplicationStatusEnum.FINANCIAL_SUBMITTED_FRAUD_DECLINE:
				if (identifier.isCashApplication || !identifier.losStatus) {
					navigationAction = '/thankyou';
					screenAction = 'thankyou';
					errorCode = 'CASH_APPLICATION';
				} else {
					  navigationAction = '/provision';
					  screenAction = 'provision';
				}
				break;
			case ApplicationStatusEnum.COLLATERAL_REQUEST_FAILED:  // Initiate the collateral valuation
				// May be a situation where offer generate request initiated but engine failed
				// how to handle at UI, shall we present the contact us screen
				screenAction = 'trade';
				navigationAction = '/trade';
				break;
			case undefined:
			case null:
			default:
				navigationAction = '/thankyou';
				screenAction = 'thankyou';
				errorCode = 'EXCEPTION';
				break;
		}
		console.log('Key method to identify somehow we land at => ', {
			navigationAction: navigationAction,
			screenAction: screenAction,
			errorCode: errorCode
		});
		console.log('navigation => navigationAction', navigationAction, screenAction);
		return {navigationAction: navigationAction, screenAction: screenAction, errorCode: errorCode};
	}

	goToUrlTarget(urlTarget: any): any {
		let navigationAction: string = '';
		let screenAction: string = '';
		let errorCode: string = '';
		switch (urlTarget) {
			case 'docsUpload':
				navigationAction = '/provision/upload-docs';
				screenAction = 'provision/upload-docs';
				break;
		}
		return {navigationAction, screenAction, errorCode};
	}

	mapErrorCodes(_error: any, identifier: KeyIdentifierModel = new KeyIdentifierModel(false)) {
		console.log('something happened, error is thrown => ', _error);
		let error = _error;
		if (_error instanceof HttpErrorResponse) {
			error = _error.error;
		}
		identifier.errorCode = error.code;
		identifier.errorMessage = error.message;
		identifier.tracker = 'errorCodes';
		// this.keyIdentifier.next(this.keyIdentifierData);
		switch (error.code) {
			case 9001:
			case 9002:
			// Exception Occurred while getting Bureau Report from TransUnion.
			// 700 API call failed.
			case 9003:
			// Exception Occurred while getting Bureau Report from 700.
			case 9004:
			// Drools PostBureau PreEligibility API call failed.
			case 9006:
			// Exception Occurred while executing PostBureau PreEligibility in Drools.
			case 9007:
			// Drools Rates API call failed.
			case 9008:
			// Exception Occurred while executing Rate rules in Drools.
			case 8888:
				// Unexpected Error Occurred while processing request.
				identifier.errorCode = 'EXCEPTION';
				this.router.navigate(['/thankyou']);
				break;
			case 6003:
				// Date format Exception occurred
				break;
			case 7001:
			case 7002:
				identifier.errorCode = 'NO_VIN';
				this.router.navigate(['/trade']);
				console.log('Navigating to vehicle screen...');
				break;
			case 5001:
			case 5002:
				// input or Mandatory Field is Null
				break;
			case 5003:
				// input or Mandatory Field is Null
				break;
			case 5004:
				this.router.navigate(['/thankyou']);
				identifier.errorCode = 'NO_OFFER';
				console.log('Navigating to vehicle screen...');
				break;
		}
		this.keyIdentifier.next(identifier);
	}

	GetObjectPropertyValue<T, K extends keyof T>(obj: T, key: K) {
		return obj[key];
	}

	/**
	 * Update Applicant data in both application.applicant model and primary applicant model
	 * @param identifier
	 * @param applicant
	 * */
	updatePrimaryApplicant(identifier: KeyIdentifierModel, applicant: ApplicantModel) {
		identifier.primaryApplicant = applicant;

		if(!identifier.application) {
			identifier.application = new ApplicationModel();
		}

		const index = identifier.application.applicants.findIndex(applicant => applicant.applicantType === 'P');
		if (index !== -1) {
			identifier.application.applicants[index] = applicant;
		} else {
			identifier.application.applicants.push(applicant);
		}
		this.keyIdentifier.next(identifier);
	}

	/**
	 * Get Applicant model from key Identifier
	 * @param keyIdentifier
	 */
	getPrimaryApplicantFromKeyIdentifier(keyIdentifier: KeyIdentifierModel) {
		let applicant = new ApplicantModel();
		if(keyIdentifier.primaryApplicant) {
			applicant = keyIdentifier.primaryApplicant;
		}
		if(keyIdentifier.application && keyIdentifier.application.applicants && keyIdentifier.application.applicants.length) {
			applicant = keyIdentifier.application.applicants.find(item => item.applicantType === ApplicantType.PRIMARY);
		}
		return applicant;
	}

	updateApplicationAndKeyIdentifier(keyIdentifier: KeyIdentifierModel) {
		return new Promise((resolve, reject) => {
			this.applicationService.findApplication(keyIdentifier.applicationId).subscribe({
				next: (res) => {
					this.keyIdentifierModel.updateKeyIdentifier(res);
					keyIdentifier.application = res;
					this.announceIdentifierChanges(keyIdentifier);
					resolve(res);
				}, error: (err) => {
					reject(err);
				}
			});
		})
	}
}
