<ng-container *ngIf="!prefilled">
  <mat-form-field class="mat-form-field-fluid">
    <mat-label class="phone-input-label">{{options.inputPlaceholder}}</mat-label>
    <mat-intl-tel-input
      [cssClass]="'mat-mdc-form-field mat-mdc-text-field-wrapper w-100' + (phoneElement?.control?.errors ? ' ng-invalid' : '')"
      [preferredCountries]="options.preferredCountries"
      [enablePlaceholder]="options.enablePlaceholder"
      [enableSearch]="options.searchCountryFlag"
      [required]="options.required"
      [format]="options.numberFormat"
      [inputPlaceholder]="options.inputPlaceholder"
      [searchPlaceholder]="'Search Country'"
      [onlyCountries]="options.onlyCountries"
      [(ngModel)]="value"
      name="phone"
      id="phone"
      #phoneInput
      autocomplete="off"
    ></mat-intl-tel-input>
    <mat-error *ngIf="phoneElement && phoneElement?.control">
      <ng-container *ngIf="phoneElement.control.hasError('required')">{{label}} is <strong>required.</strong>
      </ng-container>
      <ng-container
        *ngIf="phoneElement.control.hasError('invalid') || phoneElement.control.hasError('validatePhoneNumber')">{{label}} is not <strong>valid.</strong>
      </ng-container>
    </mat-error>

  </mat-form-field>
</ng-container>
<ng-container *ngIf="prefilled">
  <mat-form-field class="mat-form-field-fluid">
    <mat-label>{{label}}</mat-label>
    <input #phoneInput="ngModel"
           [(ngModel)]="value" matInput
           name="phone" [disabled]="true"
           required
           type="text" />
    <mat-icon matSuffix (click)="editPrefilledNumber()" style="cursor: pointer">edit</mat-icon>
  </mat-form-field>
</ng-container>
