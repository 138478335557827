export const commonTextConfig: any = {
	CONTENT: {
		NAME: 'ABC',
		SHORTNAME: 'ABC',
	},
	common:{
		driversLicenseText: 'Driver\'s License',
	},
	aboutPage: {
		pageTitle: 'No Credit Impact. Instant Offers. Page 1',
		title: 'See your {{pre-qualified}} {{loanPurpose}} offers in as little as 2 minutes.', // See your pre-qualified offers in as little as 2 minutes.
		subTitle: 'Completing the form below will not affect your credit score.',  // Completing the form below will not affect your credit score.
		cashApplicationTitle: 'Complete the form below for {{title}}',
		cashApplicationSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
		loanPurposePlaceholder: 'Pick your loan',
		coBorrowerTitle: 'Tell me about your Co-Borrower.',
		coBorrowerSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
		primaryBorrowerTitle: 'Complete the Application Below to Get Started with Purchasing Your Vehicle',
		haveOfferCodeText: 'Received a mailer from us? Use Offer Code',
		housingCostHint: 'Estimate monthly housing cost. Homeowners, include all mortgage payments (first & second mortgages, and home equity loans). Renters, please enter monthly rent.',
		grossAnnualIncomeHint: 'Your before tax income can include commissions and tips. Please do not include non-applicant income. Inclusion of alimony or child support is optional.',
		textBelowSubmitButton: 'No credit impact for checking your rates.',
		nonExistingMemberBlockMessage: 'If you are a not an existing member please visit the branch to open your account first.',
		durationInBusinessLabel: "Duration in Business"
	},
	stepPage: {
		title: 'See your pre-qualified offers in as little as 2 minutes.', // See your pre-qualified offers in as little as 2 minutes.
		subtitle: 'Completing the form below will not affect your credit score.',  // Completing the form below will not affect your credit score.
		next: 'Next',  // Next
		back: 'Back',  // Back
		reset: 'Reset',  // Reset
	},
	radioButton: {
		yes: 'Yes',  // Back
		no: 'No',  // no
	},
	tradePage: {
		pageTitle: 'Payoff and/or Collateral. Trade/Collateral Page',
		title: 'Last step before you can see your offers.', // Last step before you can see your offers.
		vehicleInformation: 'Vehicle Information', // Vehicle Information
		identificationNumber: 'Do you have your Vehicle Identification Number (VIN)?', // Do you have Vehicle Identification Number?
		beforeStep: 'Last step before you can see your offers.?', // Last step before you can see your offers.
		loanBalance: 'Current Loan Balance', // Current Loan Balance
		paymentMonthly: 'Monthly Payment', // Monthly Payment
		outstandingBalance: 'Est. Outstanding Balance', // Est. Outstanding Balance
		Change: 'Change', // Change
		informationVehicle: 'Vehicle information', // Vehicle information
		selectedVehicle: 'Your Selected Vehicle', // Your Selected Vehicle
		Mileage: 'Mileage', // Mileage
		accessories: 'Please select vehicle accessories.', // Please select vehicle accessories.
		noTradesScenarioTitle: 'We need additional information before you can see your offers.',
		loanTitle: 'Current Loan Balance',
		tradeLineTitle: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details',
		tradeLineDisplayTitle: 'Do you want to transfer balance from another {{tradeLineDisplay}} loan? Select Skip below if you are not transfering balance.',
		tradeLineErrorText: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details.',
		loanPurposeConfigHint: 'Please provide approximate outstanding loan amount. You may find on your current loan statement',
		loanPurposeConfigError: ' Please enter a valid Payoff amount value greater than {{loanAmount}}.',
		customMonthlyPayment: 'Please enter current monthly payment',
		noVehicleDisclosure: 'Choosing this option will give you preliminary loan rates. If you already have a vehicle in mind, enter that vehicle information below to receive more accurate rates.',
		noVehicleDisclosure_refi: 'Choosing this option will give you preliminary loan rates. If you are able to find vehicle information, enter vehicle information below to receive more accurate rates.',
		propertyAddressTitle: 'Update address if your property address is not the same as the address you provided',
		additionalInfoTextLong: "You are almost there! We just need a few details.",
		tradeSelectionTextLong: {
			'R': 'Select the vehicle loan that you want to refinance',
			'CC': 'Select the credit card loans that you want to consolidate',
			'CCDC': 'Select the credit card loans that you want to consolidate',
			'PPDC': 'Select the loans that you want to consolidate',
			'BCC': 'Select the credit card loans that you want to consolidate',
			'HR': 'Select the mortgage that you want to refinance',
			'HE': 'Select the mortgage associated to your current property',
		},
		vehicleSelectionTextLong: 'Awesome, now on to your ride'
	},
	offerPages: {
		jumpPageTile: 'Routing you to the next step. Routing Page',
		headlineTitle1: 'Congratulations, {{applicantFirstName}}! ', // Congratulations, Iram! Several
		headlineTitle2: 'Your pre-qualified offers are ready. ', // Congratulations, Iram! Several
        ccHeadlineTitle: 'Your {{pre-qualified}} card offers are ready.',
		cliHeadlineTitle: 'You have {{pre-qualified}} for credit limit increase.',
		termBasedLoanSubTitle: 'Select or customize your pre-qualified offer<sup>&dagger;</sup> to continue to the next step.',
		textAboveSliderInstalment: 'How much do you want to borrow?',
		textAboveSliderRevolving: 'Select your desired line of credit amount',
		buttonText: 'Select & Continue',
		asLowAs: 'as low as',
		discountText: '(Includes {{achDiscountRate}}% ACH discount)',
		preQualified: 'These Pre-Qualified offers are based on the information you provided and in your credit file. They\n' +
			'                      are initial, conditional offers and are subject to change based on completion of a final application,\n' +
			'                      verification of information you provided, and other conditions determined solely by {{institutionName}}. The\n' +
			'                      offers above are not guaranteed and not a commitment to lend. ' +
			'Pre-qualification offers are valid for 30 days. Rates will be locked in upon final submission of your application.',
		aprLegalNoteTermBasedLoan: 'The Annual Percentage Rate (APR) is the yearly rate at which your loan accrues interest. Rates will\n' +
			'                      vary based on your credit, loan size and value of collateral provided in relation to your loan amount.\n' +
			'                      Members with good credit financing vehicles with low loan-to-value ratios may qualify for the lowest rates. Minimum and maximum loan amounts and APRs offered may vary according to state law and\n' +
			'                      are subject to change.',
		paymentExample: 'Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 years would result in 48 monthly payments of $222.18. Total interest paid over full term of the loan will be $664.64.',
		aprLegalNoteNotTermBasedLoan: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit rating, ' +
			'amount financed and other factors. Rates and terms are subject to change without prior notice; ' +
			'other restrictions may apply.',
		aprCCNoteContent: 'Annual Percentage Rate (APR) is valid only on balance transfers made within 90-days of account opening for qualified applicants, based on creditworthiness and other factors. The {{balanceTransferAPR}}% APR will remain in effect for the life of balances transferred from non-{{institutionName}} credit cards within the first 90-days of account opening. Balance transfers made after 90-days of account opening do not qualify for the {{balanceTransferAPR1}}% APR.',
		achDiscountLabel: 'Do you want save by signing up for automatic payments?',
		ccSchumerBoxBillingText: 'We apply your minimum payment to balances with lower APRs first, including ' +
			'promotional APRs. Amounts paid over the minimum payment will be applied in the order of highest '+
			'to lowest APR balances. If APRs are the same, then payment is applied to Purchase balances '+
			'before applying to Balance Transfers and then Cash Advances.',
		referenceIdText: null,
	},
	warrantyProductsPage: {
		headline: 'Protection Product Options',
		subLine: 'Choose from these additional protection products available to you. Products offered below are optional. Purchasing the following products is not required to obtain financing.',
		disclosure1:'*There are eligibility requirements, conditions and exclusions that could prevent you from purchasing these products. Until your loan terms are final, product eligibility and pricing could change. Cost estimates provided are based on the loan terms selected in this application.',
		declineButton: 'I do not want Protection',
		submitButton: 'I elect to buy this protection',

	},
	financePage: {
		pageTitle: 'Review and Submit. Final Submit Page',
		headline: 'Your application is almost ready for submission.',
		subHeadline: null,
		mobileHeadline: 'Your application is almost ready for submission.',
		pendHeadline: 'Based on the information provided, your application needs further review. Please provide additional details so we can expedite the review of your application.',
		pendSubHeadline: null,
		pendSubHeadline2: null,
		employmentLengthHelpTextYear: 'Please enter years of employment.',
		employmentLengthHelpTextMonth: 'Please enter month of employment.',
		employmentStatusHelpText: 'Select Part Time if working less than 30 hours a week',
		noCollateralScenarioTitle: 'The presented offer is without the vehicle value. Please add the collateral information to get the final offer.',
		noOfferStatusTitle: 'No offers available for your selections. Please change your loan amount and/or other selections to see available offers',
		downPayment: '(Estimated. Final down payment amount may vary)',
		applicationCheckboxTitle: 'By clicking the Submit button below, all borrowers consent, acknowledge, and agree to the following:',
		taxIDDisplayText: 'Social Security Number',
		taxIDErrorText: 'SSN is required',
		finalSubmitLoaderMessage: '', //Your application is in transit to {{lenderName}}.
		additionalTextAboveFinalSubmitButton: null,
		additionalTextBelowFinalSubmitButton: null,
		desiredLoanAmountLabel: 'Desired Loan Amount',
		desiredLoanTermLabel: 'Desired Loan Term',
		beneficialOwnerTitle: 'Beneficial Owners and Investors',
		beneficialOwnerDescription: 'Include any Individual or Entity with 25% or more ownership in this business',
		controllingManagerDescription: 'A single individual with significant responsibility to control, manage, or direct a legal' +
		'entity customer (e.g., a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President,'+
		'Vice President, Treasurer or any other individual who regularly performs similar functions',
		controllingManagerTitle: 'Controlling Manager',
		guarantorTitle: 'Guarantor of Credit',
	},
	uwDecisionPage: {
		nextStepsText: `Ready for Next Steps?`,
		reviewText: `While we are reviewing your application, get a headstart by completing some next steps.`,
		nextStepsButton: 'Continue',
		textBelowContinue : null,
	},
	myAppPage: {
		title: 'Welcome back!',
		subheadLine: 'Please complete the information below to return to your application.',
		noAppInListMessage: `Do not see your application in this list? <a
		href="/" id="clickHereToStartNewApplication">Click here</a> to start a new application.`,
		noAppFoundMainMessage: `Oops! No application found. Please verify your information and try again.`,
		noAppFoundMessage: `If you have not completed an application yet
                  <a href="/" id="clickHereToStartNewApplication" >Click here </a> to start a new application.`
	},
	documentUploadPage: {
		subHeadline: 'We need additional information to complete processing this application. Please upload these documents.',
		footerLine: 'If you need more time, you may return to this page using the link from the email, text, or by clicking the My Application option above.',
		buttonName: 'Select Document',
	},
	thankyouPage:{
		pageTitle:null,
		subheadLine: null,
		html:null
	},
	headerMenu: {
		title: 'ABC UAT HEADER'
	},
	footerMenu: {
		title: 'ABC UAT FOOTER'
	},
	myOfferPage: {
		title: 'Congratulations on receiving your offer',
		subTitle: 'Enter your information to find your offers.',
		offerCode: 'RSVP Code',
		starDisclosure: null,
		noAppFoundMessage: `Please try re-entering or <a href="/" >click here</a> to continue without a code.`
	},
	achPage: {
		title: 'Set Up Automatic Loan Payments',
		subTitle: 'We make it easy to set-up Automatic Payments for your loan! Simply enter the payment information for your existing financial institution below.'
	},
	MESSAGES: {
		OFFERS: {
			LENDER_APPROVED_NO_RATES: {
				TITLE: 'Thank you for completing the application!',
				MESSAGE: 'Thank you for submitting your application. We need additional information before ' +
					'you can see your offer. Please contact your {{institutionName}} ' +
					'Member Services Representative at {{phoneNumber}} so that we can get you the loan that meets your needs.'
			},
			NO_BUREAU_REPORT: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_BUREAU_HIT_COBORROWER: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER: {
				TITLE: '',
				MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
			},
			PEND: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			CROSS_SELL_DISCLOSURE: {
				MESSAGE: ''
			},
			NO_OFFER_NO_HIT: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER_HARD_DECLINE: {
				TITLE: '',
				MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
			},
			NO_OFFER_FRAUD_DECLINE: {
                TITLE: 'Sorry! We have no offers available at this time',
                MESSAGE: 'Unfortunately we are unable to find an offer based on the information you provided. We ' +
                'really appreciate your business and hope you will consider us again. We are continuously ' +
                'adding new offers, so you can try again in 3 months if you are still interested.'
            },
            FRAUD: {
                TITLE: 'Sorry! We have no offers available at this time',
                MESSAGE: 'Unfortunately we are unable to find an offer based on the information you provided. We ' +
                'really appreciate your business and hope you will consider us again. We are continuously ' +
                'adding new offers, so you can try again in 3 months if you are still interested.'
            },
			NO_OFFER_FOUND: {
				TITLE: '',
				MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
			},
			NO_OFFER_SOFT_DECLINE: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER_PRE_FAIL: {
				TITLE: '',
				MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
			},
			NO_OFFER_WITH_LTV: {
				TITLE: '',
				MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
			},
			NO_OPTIONS: {
				MESSAGE: 'Thank you for submitting your request. At this time, we are unable to find a suitable offer for you.'
			},
			PAYMENT_EXAMPLE: {
				MESSAGE: 'Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 ' +
					'years would result in 48 monthly payments of $222.18.'
			},
			APR_LEGAL_NOTE: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit ' +
					'worthiness, amount financed and other factors.  Rates and terms are subject to change without prior ' +
					'notice. Other restrictions apply.'
			},
			APR_LEGAL_NOTE_Non_Auto: {
				MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit worthiness, ' +
					'amount financed and other factors. Rates and terms are subject to change without prior notice. ' +
					'Other restrictions may apply.'
			},
			PRE_QUALIFIED_OFFER: {
				MESSAGE: 'The Pre-Qualified offers presented above are based on the information you provided and information from ' +
					'your credit file. These are initial, conditional offers that are subject to change, based on the completion of a ' +
					'final credit application, verification of the information you provided, your ability to repay, and other conditions. ' +
					'The offers are non-binding, are not guaranteed, and are not a commitment to lend'
			},
			DECLINE: {
				TITLE: 'Thank you for applying',
				MESSAGE: '<p>We will get back to you as soon as we have updated on your application.</p>'
			},
		},
		CASH_APPLICATION: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our Representatives will be contacting' +
				' you in the next 1-2  business days 24 hours with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		PROVISIONING_COMPLETE: {
			TITLE: 'You are all set.',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		FINANCIAL_SUBMITTED: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		PRE_FINANCIAL_SUBMITTED: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		FINANCIAL_SUBMITTED_FAILED: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		FINANCIAL_SUBMITTED_FRAUD: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		FINANCIAL_SUBMITTED_FRAUD_REVIEW: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		FINANCIAL_SUBMITTED_FRAUD_DECLINE: {
			TITLE: 'Thank you for completing the application!',
			MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
				'contacting you in the next 1-2 business days with the next steps. ' +
				'You can reach us at {{phone}} if you have any questions in the meantime.'
		},
		EXCEPTION: {
			TITLE: 'Something unexpected happened!',
			MESSAGE: 'Our expert Member Service Representatives will be able to take care of you. Please call ' +
				'{{phoneNumber}} to speak to your dedicated {{institutionName}}Member Service Representative.'
		},
		DISCLOSURE: {
			FINAL: {
				CONSENT_1: 'You are providing written consent under the Fair Credit Reporting Act to authorize {{institutionName}} to',
				CONSENT_2: 'obtain your credit report with a hard credit inquiry ',
				CONSENT_3: ' from credit reporting agencies. You acknowledge that this is an application for credit and authorize ' +
					'{{institutionName}} to obtain such information solely to evaluate your credit application. ' +
					'By clicking this box, you consent, acknowledge, and agree that {{institutionName}} can process your final application for credit.' +
					'You are also consenting to allow us offer you savings and benefits on other products.'
			},
			SOFT_INQUIRY: {
				CONSENT_1: ' You are providing express written consent under the Fair Credit Reporting Act to authorize ' +
					'{{institutionName}} to obtain your credit profile from credit reporting agencies. You authorize ' +
					'{{institutionName}} to obtain such information solely to evaluate your eligibility for certain loan product ' +
					'pre-qualification offers and other cross-sell offers and acknowledge that this is not an application for credit.',
				CONSENT_UPTO_LENDER: '',
				CONSENT_PRIVACY: 'You agree that you received {{institutionName}} <a ' +
					'href="" target="_new">Privacy Policy</a>. ' +
					'You also verified that you can read and print or keep these electronic documents and you ' +
					'certify that your selections and information in this form are true, correct and complete.',
				CONSENT_MARKETING: 'I hereby consent to receive email, SMS or other marketing communications from {{institutionName}}.' +
					'I further expressly agree that {{institutionName}} may contact me at the calling numbers providing including ' +
					'my cellular number, via alive, auto dialer, prerecorded telephone call, text chat or email message. ' +
					'I further understand that my telephone company may impose charges on me for these contacts and I am not ' +
					'required to provide this consent to be effective even if my calling numbers or email address are included on ' +
					'ado-not-call list or registry. I understand that my consent to receive calls is not required in order to ' +
					'purchase any goods or services. If you do not want to receive any marketing communications just un-check the box.'
			},
			MARKETING_INQUIRY: {
				CONSENT_MARKETING_1: 'I hereby consent to receive email, SMS or other marketing communications from {{institutionName}}. ' +
					'I further expressly agree that {{institutionName}} ',
				CONSENT_MARKETING_2: ' may contact me at the calling numbers providing including my cellular number, via live, ' +
					'auto dialer, prerecorded telephone call, text, chat or email message. I further understand that my telephone ' +
					'company may impose charges on me for these contacts and I am not required to provide this consent to be effective even if ' +
					'my calling numbers or email address are included on a do-not-call list or registry. I understand that my ' +
					'consent to receive calls is not required in order to purchase any goods or services. ' +
					'If you do not want to receive any marketing communications just un-check the box'
			}
		},
		PROVISIONS: {
			THANKYOU: {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
			'THANKYOU-APPROVED': {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
			'THANKYOU-INREVIEW': {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
			'THANKYOU-DECLINE': {
				TITLE: 'We appreciate your interest in refinancing with us.',
				MESSAGE: 'However, we are unable to extend credit to you currently.  We will provide you with an adverse action notice within 30 days regarding our decision.'
			},
		}
	},
	steps: {
		accountSelection: 'Account Selection',
		personalInformation: 'Personal Information',
		vehicleInformation: 'Vehicle Information',
		currentLoanInformation: 'Current Loan Information',
		agreements: 'Agreements',
		funding: 'Funding',
	},
	productSelection: {
		title: 'Here’s What You\'ll Need to apply for a new loan',
		description: '<ul class="checkmark">\n' +
			'<li>ID for Identity Verification</li>\n' +
			'<li>Social Security Number</li>\n' +
			'<li>Income Information</li>\n' +
			'</ul>',
		getStartedButtonLabel: 'Get Started'
	},
	loanCategories: {
		AUTO: {
			name: 'Vehicle Loans',
			icon: 'directions_car',
			description: 'Whether you are in the planning process or ready to buy or looking to refinance, see how much you can save without impacting your credit.',
		},
		AUTO_SPECIALTY: {
			name: 'Special Vehicle Loans',
			icon: 'directions_boat',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		CREDIT_CARD: {
			name: 'Credit Cards',
			icon: 'credit_card',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		BUSINESS_LENDING: {
			name: 'Business Loans',
			icon: 'currency_exchange',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		OTHER: {
			name: 'Other',
			icon: 'list_alt',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		personal: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		}
	},
	loanPurpose: {
		BPP: {
			name: 'Vehicle Loans',
			icon: 'directions_car',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		BCC: {
			name: 'Special Vehicle Loans',
			icon: 'directions_boat',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		PP: {
			name: 'Credit Cards',
			icon: 'credit_card',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		PLOC: {
			name: 'Business Loans',
			icon: 'currency_exchange',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		PPDC: {
			name: 'Other',
			icon: 'list_alt',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		CR: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		R: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		R_PROMO: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		AU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		AN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		CC: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		CLI: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		SSECURED: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		CCDC: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		RV: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		RVN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		RVU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		RVR: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		M: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		MN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		MU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		MR: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		B: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		BN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		BU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		BR: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TFE: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TFEN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TFEU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TFER: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TT: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TTN: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TTU: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
		TTR: {
			name: 'Personal Loans',
			icon: 'person_check',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		},
	},
	purposeCodeList: {
		'Medical Expenses': {
			name: 'Medical Expenses',
			icon:'directions_car',
			description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
		},
		'Debt Consolidation': {
			name: 'Debt Consolidation',
			icon:'directions_car',
			description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
		},
		'Wedding': {
			name: 'Wedding',
			icon:'directions_car',
			description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
		}
	},
	loanPurposes: {
		PP: {
			name:'Vehicle Refinance',
			icon:'directions_car',
			description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
		},
	}
}
